.flat-list-page{
  &__front-image{
    img{
      display: block;
      width: 100%;
    }
  }
  &__main-text{
    margin: 100px 0 200px 0;
    @include mq($until: md) {
      margin: 50px 0 100px 0;
    }
    p{
      font-size: rem(20px);
    }
  }
}

.flat-list {
  @include mq($until: sm) {
    margin-top: 40px;
  }
  &__wrapper {
    // margin-top: 140px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    @include mq($until: md) {
      margin-bottom: 80px;
    }
  }
  &__head {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-content: stretch;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 5px;
    background-color: white;
    // @include mq($until: xl) {
    //   grid-template-columns: 1fr 2fr 1fr 1fr 2fr 2fr 2fr 2fr;
    // }
    // @include mq($until: lg) {
    //   grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr 2fr 2fr;
    //   grid-gap: 5px;
    // }
    @include mq($until: md) {
      display: none;
    }
    position: relative;

  }
  &__head-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fw-medium;
    font-size: rem(20px);
    color: #3F3F3F;
    font-family: $font-family;
    &--action{
      cursor: pointer;
      transition: color .3s;
      &:hover{
        color: $color-extra
      }
    }
    &.active{
      color: $color-extra
    }
    i{
      margin-right: 5px;
      padding-bottom: 9px;
    }
    &[data-order="asc"]{
      i{
        transform: scale(1);
      }
    }
    &[data-order="desc"]{
      i{
        transform: scale(-1);
      }    
    }
    @include mq($until: xxl) {
      font-size: rem(16px);
    }

    @include mq($until: xl) {
      font-size: rem(13px);
    }
    
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__item {
    display: grid;
    padding: 0 20px;
    grid-template-columns: repeat(8, 1fr);
    // display: flex;
    // justify-content: space-around;
    justify-content: stretch;
    align-items: center;
    transition: 0.3s;
    position: relative;
    margin-bottom: 5px;
    background-color: #fdf8f2;
    transition: all .3s;
    // width: 100%;
    a:visited{
      background-color: $color-main;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(2n) {
    background-color: #FFF8EE;
      a:visited{
        background-color: $color-main;
      }
    }
    &:hover span{
      color: $color-extra;
    }

  
    // &.wolne:hover {
    //   background-color: #CEB275;
    // }
    // &.sprzedane:hover {
    //   background-color: #3F3F3F;
    // }
    // &.rezerwacja:hover {
    //   background-color: #2C3836;
    // }
    // @include mq($until: xl) {
    //   grid-template-columns: 1fr 2fr 1fr 1fr 2fr 2fr 2fr 2fr;
    // }
    // @include mq($until: lg) {
    //   grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr 2fr 2fr;
      
    // }

    @include mq($until: md) {
      display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // justify-content: flex-start;
      // align-items: flex-start;
      flex-direction: column;

      grid-gap: 0;
      padding: 20px;
    }
    @include mq($until: sm) {
      flex-direction:column;
      align-items: center;
    }
  }
  &__status {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 5px;
  }
  &__info {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      justify-content: flex-start;
      font-weight: $fw-bold;
      font-size: rem(18px);
      padding: 0;
      flex-basis: 100%;
      margin-bottom:21px;
      &:first-child{
        // margin-bottom: 13px;
      }
      &:last-child{
        margin-left: auto;
        // margin-bottom: 5px;
        @include mq($until: sm) {
         margin-left: unset;
        }
      }
    }
    // @include mq($until: xs) {
    //   justify-content: space-between;
    //   margin-top: 5px;
    // }
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    &--button{
      @include mq($until: md) {
        flex-basis: 48%;
        .flat-list__item-label{
          display: none;
        }
        .flat-list__item-value{
          width: 100%;
          button, a, span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0;

          }
          
        }
      }
    }

  }
  &__item-value{
    position: relative;
  }
  &__item-label {
    display: none;
    @include mq($until: md) {
      display: block;
      margin-right: 10px;
      font-weight: $fw-regular;
      font-size: rem(20px);
      display: inline;
    }
  }
  &__item-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
      font-size: rem(18px);
      font-weight: $fw-bold;
      display: inline;
    }
    span:first-letter {
      text-transform: uppercase;
    }
    span,
    a {
      font-weight: 600;
      margin-left: 5px;
      color: #3F3F3F;
      transition: all .3s;
      @include mq($until: xxl) {
        font-size: rem(14px);
      }
      @include mq($until: md) {
        font-size: rem(18px);
      }
    }
    svg {
      height: 20px;
      fill: $color-main;
      transition: 0.2s;
      margin-right: 5px;
      @include mq($until: xl) {
        height: 21px;
      }
    }
    a,
    button {
      border: 1px solid #000;
      background-color: #fff;
      padding: 5px 10px;
      transition: 0.2s;

      &:hover {
        border: 1px solid $color-main;
        background-color: $color-main;
        color: $color-font-light;
        svg {
          fill: $color-font-light;
          @include mq($until: md) {
            // width: 10px
          }
        }
      }
      @include mq($until: md) {
        padding: 5px 8px;
        font-size: rem(16px);
        font-weight: $fw-semi-bold;
      }
    }
  }

  &__no-results {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: rem(26px);
    color: $border-color;
  }

}
.pdf-butons{
  display: flex;
  align-items: center;
  @include mq($until: md) {
    a{
      margin-bottom: 5px;
    }
  }


  &__separator{
    padding: 0 5px;
    opacity: .3;
    @include mq($until: md) {
      padding: 0 13px;
    }
  }
}
