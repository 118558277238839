.icons-set {
  display: grid;
  text-align: center;
  gap: 1vw;
  margin-bottom: 9vw;
  margin-top: 4vw;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    max-width: 200px;
    justify-self: center;

    img {
      max-width: 105px;
      // filter: opacity(0.8) drop-shadow(0 0 0 $color-main) contrast(250%) contrast(190%) brightness(110%);
    }

    &::before {
      content: none;
    }

  }
  

  &__item-text {
      margin-top: 15px;
      font-size: rem(15px);
      color: #4F647C;
  }

  @include mq($until: md) {
    row-gap: 5vw;
    margin-top: 5vw;
    &__item {
      img {
        width: 60px;
      }
    }
    &__item-text {
      font-size: rem(14px);
    }
  }

  @include mq($until: sm) {
    &__item {
      img {
        width: 45px;
      }
    }

    &__item-text {
      font-size: rem(13px);
    }
  }
}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.slick-disabled {
  opacity: .5;
}

.slick-arrow {
  display: block;
  position: absolute;
  top: 5%;
  background-color: transparent;
  z-index: 2;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: rgb(223, 223, 223);
  border-color: rgb(223, 223, 223);
  border-style: solid;
  font-size: rem(25px);
  transition: all .4s;
  &:hover {
      color: white;
      border-color: white;
      color: $color-main;
      border-color: $color-main;
  }

  &.slick-disabled {
      &:hover {
          color: rgb(223, 223, 223);
          border-color: rgb(223, 223, 223);
      }
  }

  &.slick-prev {
      left: initial;
      right: 15%;
      i {
          padding-right: 5px;
      }
  }

  &.slick-next {
      right: 9%;
      i {
          padding-left: 5px;
      }
  }

  @include mq($until: xl) {
    top: 0;  
  }


  @include mq($until: md) {
    &.slick-next {
        right: 50px;
    }

    &.slick-prev {
      right: 130px;
    }
  }

  @include mq($until: xs) {
    &.slick-next {
        right: 15px;
    }

    &.slick-prev {
      left: 15px;
    }
  }
}