.st0 {
  cursor: pointer;
  transition: opacity .3s;
}

.flatlist-related__tip {
  display: none;
  position: absolute;
  z-index:  10;
  @include mq($until: xl) {
    svg {
      width: 220px;
    }
  }
}

.tooltips {
  display: grid; 
  grid-template-columns: repeat(18, 1fr);
  max-width: 80%;
  margin: 0 auto;
}

.legend {

  display: flex;
  margin-bottom: -20px;
  @include mq($until: md) {
    margin-bottom: 50px;
    justify-content: center;
  }

  & > div {
      display: flex;
      align-items: center;
      margin-right: 25px;
  }

  i {
      color: #777777;
      font-size: 14px;
  }

  span {
      width: 15px;
      height: 15px;
      border: 2px solid white;
      box-shadow: 0px 0px 5px #00000029;
      border-radius: 50%;
      display: block;
      margin-right: 5px;
  }

  .wolne {
      background-color: #7aff68;
  }

  .rezerwacja {
      background-color: #fff046bb;
  }

  .sprzedane {
      background-color: #a30000c2;
  }

}

.st0 {
  opacity:0;
  fill:#8aff7a7c;
  enable-background:new;

  &.rezerwacja {
      fill:#fff04673;
  }

  &.sprzedane {
      fill:#a3000080;
  }
}
