.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-bottom: 13px;
    &> *{
      opacity: 0.5;
    }

    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }

    // &__copyright {
    //   position: absolute;
    //   left: calc(50% - 80px);
    // }

    @include mq($until: md) {
      flex-wrap: wrap;

      &__copyright {
        position: static;
      }
      
    }
    @include mq($until: xs) {
      &__realization img {
        margin-left: 5px;
        width: 25px;
      }

      p, a , span, div {
        font-size: rem(12px);
      }
    }
}

.footer {

  &__logo {
    img {
      width: 150px;
      margin-bottom: 20px;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__consultants {
    margin: 0;
  }

  &__social {
    width: 150px;
    display: flex;
    justify-content: center;

    .contact-data__section {
      .svg-icon {
        margin: 0;
      }
    }

    svg {
      fill: white;
      opacity: 0.6;
      width: 34px;

      &:hover {
        opacity: 1;
      }
    }
  }

  @include mq($until: lg) {

    &__address {
      justify-content: center;
    }

    &__consultants {
      li {
        display: flex;
        flex-direction: column;
      }

      span, br {
        display: none;
      }
    }
  }

  @include mq($until: sm) {
    &__address {
      justify-content: flex-start;
      margin-top: 10px;
    }  
    &__consultants {
      display: flex;
      justify-content: space-between;
    }
  }
  
  @media (max-width: 630px) {
    &__consultants { 
      flex-direction: column;
    }

    &__address {
      text-align: left;
      justify-self: center;
    }
  }

  @media (max-width: 390px) {
    &__address {
      text-align: center;
    }
  }
}
.line{
  width: 100%;
  height: 1px;
  background-color: grey;
  margin-bottom: 13px;
  opacity: .2;
}
.footer-devs{
  opacity: .5;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  &__separator{
    margin-right: 21px;
    margin-left: 21px;
    @include mq($until: xs) {
      display: none;
    }
  }
  @include mq($until: xs) {
    flex-wrap: wrap;
    a{
      margin-right: 21px;
      margin-left: 21px;
      padding-bottom: 13px;
    }
    word-break: keep-all;
  }
}