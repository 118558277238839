.button {
  color: #3F3F3F;
  padding: 15px 25px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: #FFF8EE;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: 1px solid #C1BBA9;
  align-self: flex-start;
  cursor: pointer;
  width: 100%;
  font-size: rem(17px);

  &:hover {
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;
  }

  &--secondary{
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }
}