.first {

    position: relative;

    h1 {
        text-align: center;
        color: white;
        text-shadow: 0px 3px 36px #00000093;
        font-size: rem(100px);
        z-index: 1;
    }

    h2 {
        color: white;
    }

    &__slogan {
        margin: 0 auto;
        padding: 80px 40px 10px;
        background: #00000073;
        margin-top: -80px;
        z-index: 0;
    }

    &__image{
        img {
            height: 100vh;
            object-fit: cover;
            object-position: bottom;
        }
    }

    &__heading {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    @include mq($until: lg) {
        img {
            height:80vw;
            object-fit: cover;
        }
        h1 {
            font-size: 9vw;
            margin-bottom: 2.5vw;
        }

        h2 {
            font-size: 3vw;
        }

        &__slogan {
            padding: 6vw 40px 10px;
            margin-top: -7vw;
        }
    }
    

    @include mq($until: sm) {

        img {
            object-fit: cover;
        }
    }

    @include mq($until: xs) {
        img{
            height:110vw;
        }
        &__slogan {
            padding: 6vw 4vw 0;
        }
    }
}

.icons-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 5vw;
    &__item{
        padding-top: 3vw;
        padding-bottom: 3vw;
        max-width: 15%;
        min-width: 180px;
        display: flex;
        align-items: center;
        flex-direction: column;
        img{
            opacity: 0.4;
        }
        svg{
            width: 5vw;
            @include mq($until: md){
                width: 8vw;
            }
            @include mq($until: sm){
                width: 13vw;
            }
        }
        @include mq($until: md){
            min-width: 40%;
        }
        p{
            text-align: center;
            padding-top: 21px;
            @include mq($until: md){
                font-size: rem(15px);
            }
            @include mq($until: xs){
                font-size: rem(21px);
            }
        }
    }
}

.second{
padding-bottom: 13vw;
    &__content{
        display: flex;
        align-items: center;
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        @include mq($until: md){
           flex-direction: column;
           align-items: row;
        }
    }
    &__text{
        padding-left:3vw;
        max-width: 28%;
        position: relative;
        h2, p{
            color: #fff;
            line-height: 1.3;
        }

        p {
            line-height: 1.7;
        }
        @include mq($until: md){
            max-width: 70%;
            padding-top: 34px;
        }
        @include mq($until: sm){
            padding-left:8vw;
        }
        @include mq($until: xs){
            max-width: 80%;
        }
    }
    &__photo{
        width: 100%;
        img {
            max-height: 40vw;
            object-fit: cover;
            @include mq($until: sm){
                max-height: 55vw;
            }
        }
        width: 100%;
        &> p {
          width: 100%;  
        }
        @include mq($until: md){
            max-width: 90%;
            margin-right: auto;
        }
        @include mq($until: xs){
            max-width: 95%;
        }
    }
    &__bg-color{
        position: absolute;
        background-color: #3F3F3F;
        height: 120%;
        width: 90%;  
        right: 0;
        z-index: -1;
        overflow:hidden;
        @include mq($until: md){
            height: 80%;
            bottom: -10%;
        }
        @include mq($until: md){
            height: 90%;
        }
         
    }
    &__color-element {
        position: relative;
        z-index: 1;
        color: #c7d4b1;
        font-size: 400px;
        opacity: .2;
        right: -10%;
        bottom: -34%;
        font-family: $font-family;
        @include mq($until: lg){
            font-size: 300px;
            bottom: -45%;
        }
        @include mq($until: md){
            font-size: 34vw;
            bottom: -30%;
        }
        @include mq($until: sm){
            bottom: -30%;
        }
        @include mq($until: xs){
            bottom: -50%;
        }
    }
}

.third{
padding-bottom: 13vw;
    &__content{
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        @include mq($until: md){
           flex-direction: column;
           align-items: row;
        }
    }
    &__text{
        padding-right:3vw;
        max-width: 28%;
        position: relative;
        h2, p{
            color: #fff;
            line-height: 1.3;
        }
        p {
            line-height: 1.7;
        }
        @include mq($until: md){
            max-width: 80%;
            padding-top: 34px;
            padding-bottom: 34px;
            padding-right: 34px;
        }
        @include mq($until: sm){
            padding-left:8vw;
        }
        @include mq($until: xs){
            max-width: 86%;
            padding-left:0;
        }
    }
    &__photo{
        width: 100%;

        img {
            max-height: 40vw;
            object-fit: cover;
            @include mq($until: sm){
                max-height: 55vw;
            }
        }
        @include mq($until: md){
            max-width: 90%;
            margin-left: auto;
        }
        @include mq($until: xs){
            max-width: 93%;
        }
    }
    &__bg-color{
        position: absolute;
        background-color: #8b9f0b;
        height: 120%;
        width: 90%;  
        left: 0;
        z-index: -1;
        overflow:hidden;
        @include mq($until: md){
            height: 80%;
        }
    }

    &__color-element{
        position: relative;
        z-index: 1;
        color: #7996b8;
        font-size: 400px;
        opacity: .2;
        left: -10%;
        bottom: -34%;
        font-family: $font-family;
        @include mq($until: lg){
            font-size: 300px;
            bottom: -45%;
        }
        @include mq($until: md){
            font-size: 400px;
            bottom: -30%;
        }
        @include mq($until: sm){
            font-size: 300px;
            bottom: -20%;
        }
    }

    @include mq($until: xs) {
        margin-top: 30px;
    }
}

.fourth {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 8vw;
    &--second{        
        .fourth__text {
            text-align: left;
        }
        padding-top: 8vw;
        padding-bottom: 8vw;
        background-color: #FFF8EE;
        @include mq($until: lg){
            padding-top: 14vw;
            padding-bottom: 14vw;
        }
        @include mq($until: md){
            flex-direction: column-reverse;
            padding-top: 45px;
            padding-bottom: 45px;

            img {
                // opacity: 1!important;
            }
        }
    }
    &__text{
        position: absolute;
        z-index: 2;
        width: 32%;
        right: 0;
        text-align: center;
        z-index: 2;
        align-items: center;
        a {
            display: block;
            font-size: rem(20px);
            text-decoration: underline solid 1px #3F3F3F;
            font-family: $font-family;
        }

        h2, p, a{
            color: #3F3F3F;
            line-height: 1.3;
            margin-top: 30px;
        }

        h2 {
            text-transform: none;
        }

        b {
            font-size: rem(53px);
        }

        p {
            line-height: 1.7;
            font-size: rem(19px);
    
        }
        &--duzy-opis{
            width: 75%;
            padding: 3vw 19vw 3vw 5vw;
            font-size: rem(19px);
            h2, p{
                color: black;
            }

            h4 {
                text-transform: initial;
            }
            @include mq($until: xl) {
                padding: 3vw 12vw 3vw 5vw;  
            }
            @include mq($until: lg){
                width: 72%;
                padding: 3vw 6vw 3vw 5vw;  
            }
            @include mq($until: md){
                position: static;
                width: 100%!important;
                padding: 8vw 50px;
            }
            @include mq($until: sm){
                padding: 8vw 30px;
            }
            @include mq($until: xs){
                padding: 8vw 15px;
            }
        }

        @include mq($until: md){
            text-shadow: 0 0 6px white;
            // width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            width: 65% ;
            text-align: center;
            transform: translateX(-26%);
            
        }
    }

    &__text-gradient{
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 100%;
        right: 0;
        opacity: .8;
        background: rgb(9,46,24);
        background: linear-gradient(90deg, rgba(9,46,24,0) 0%, rgba(6,38,18,0.6068802521008403) 34%, rgba(0,23,4,1) 100%);
    }

    &__photo{
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        &> p {
            width: 100%;
        }

        img {
            height: 80vh;
            object-fit: cover;

            @include mq($until: md) {
                opacity: .3;
            }
        }

        &--opis{

            img {
                width: 25%;
                object-position: right;
                box-shadow: 0px 0px 21px #00000029;
                height: 660px;
                @include mq($until: lg) {
                    width: 28%;
                    height: 580px;
                }

            }     

            @include mq($until: md) {
                img {
                    width: 100%;
                    height: 500px;
                    object-position: bottom;
                }

                p {
                    padding-left: 50px;
                    padding-right: 50px;
                }
            }

            @include mq($until: sm) {
                p {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            @include mq($until: xs) {
                p {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            @include mq($until: md){
                position: static;
            }
        }

    }

    @include mq($until: sm) {
        &__text {
            // width:100%;
            // min-width: 300px;

            h2 {
                margin: 15px 0;
            }
        }

        img {
            height: 450px;
            object-fit: cover;
        }
    }
}
.description{
    display: flex;
    padding-top: 5vw;
    padding-bottom: 5vw;
    // background-color: #FFF8EE;
    background-color: #E0AC6A;
    @include mq($until: md) {
        flex-direction: column;
    }
  
    &__photo{
        // position: -webkit-sticky;
        // position: sticky;
        // align-items: center;
        // display: flex;
        
        img{
            padding-right: 5vw;
            position: relative;
            position: -webkit-sticky;
            position: sticky;
            top: 5vw;
            min-width: 600px;
            max-width: 40vw;
            object-fit: cover;
            @include mq($until: xl) {
                min-width: 400px;
            }
            @include mq($until: md) {
                width: 100%;
                min-width: unset;
                max-width: unset;
                padding-bottom: 34px;
                height: 60vw;
                object-position:center bottom;
            }
        }
    }
    &__text{
 
            text-align: justify;
        
        @include mq($until: md) {
            width: 83%;
            margin-left: auto;
            margin-right: 34px;
            
        }
    }
    &__more-link{
        display: inline-block;
        color: $color-extra;
        padding: 13px 21px;
        border: 1px solid $color-extra;
        // background-color: $color-extra;
        color: $color-extra;
        transition: all .4s ease-in-out;
        margin-top: 34px;
        &:hover{
           
             background-color: $color-extra;
        }
    }
}

#more {display: none;}

.fifth {
    padding-bottom: 8vw;

    .icons-bar {
        max-width: 800px;
        margin: 0 auto;
    }

    h3 {
        text-transform: none;
        font-weight: 500;
        text-align: center;
        max-width: 1200px;
        margin: 0 auto 30px;
    }

    &__columns{
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }

    &__text{
        width: 28%;
        min-width: 250px;
        p {
            margin-bottom: 0;
            text-align: center;
            font-size: rem(19px);
        }
    }

    &__introduction {
        width: 88%;
        margin: 0 auto;
        h4 {
            text-align: center;
        }
    }

    @include mq($until: md) {
        &__columns {
            flex-direction: column;
            &> div {
                margin-bottom: 25px;

                &:nth-child(2) {
                    margin-left: auto;
                    margin-right: auto;
                }

                &:nth-child(3) {
                    margin-left: auto;
                }
            }
        }
    }

    @include mq($until: xs) {
        &__introduction {
            width: 100%;
            h4 {
                text-align: justify;
                line-height: 1.5;
            }
        }

        &__columns {
            &> div {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
.map{ 
    @include mq($until: md){
        pointer-events: none;
    }
}
.six{
    padding-bottom: 8vw;
    &__wrapper{
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
        @include mq($until: md){
            flex-direction: column;
        }

    }

    &__left{
        padding: 3vw 5vw 3vw 5vw;
        background-color: #4F647C;
        width: 80%;

        h3{
            margin-top: 0;
            color: #EDEFF2;
            font-family: $secondary-font;
            text-transform: initial;
        }

        p{
            color: #EDEFF2;
        }

        @include mq($until: md){
            width: 100%;
            padding: 5vw 5vw 5vw 5vw;
        }
    }
    &__right{
        padding: 3vw 5vw 5vw 5vw;

        h4{
            margin-top: 0;
            color:#4F647C;
            font-family: $secondary-font;
            text-transform: initial;
        }

        p{
            color: #4F647C;
        }

        @include mq($until: md){
            background-color: #EDEFF2;
            width: 100%;
            padding: 5vw 5vw 3vw 5vw;
        }
    }
    &__bg{
        position: absolute;
        z-index: -1;
        width: 70%;
        height: 110%;
        right: 0;
        background-color: #EDEFF2;
        @include mq($until: md){
           display: none;
        }
    }
}

.seven {

    background-color: #3F3F3F;
    padding-top: 5vw;
    padding-bottom: 5vw;
    min-height: 65vw;
    overflow: hidden;

    &--second{
        background-color: unset;

        h3 {
            font-family: $font-family;
            text-transform: none;
            max-width: 1000px;
            line-height: 1.5;
        }
    }
    
    &__wrapper{
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        padding-top: 5vw;
        background-color: #8b9f0b;
    }
    &__wrpper{
        background-color: #8b9f0b;
    }
    &__head{
        padding-bottom: 5vw;
        padding-left: 16%;
    }

    &__photo{
        position:absolute;

        p img{
            height: 25vw;
            box-shadow: 0px 0px 21px #00000029;
            border: 1px solid #707070;
        }

        &--1{
            position:absolute;
            left: -12vw;
            z-index: 1;
            top:18vw
        }

        &--2{
            position:absolute;
            z-index: 2;
            left: 16vw;
        }

        &--3{
            position:absolute;
            z-index: 3;
            top:18vw;
            right: 16vw;
        }

        &--4{
            position:absolute;
            right: -12vw;
            z-index: 4;
        }
    }

    @include mq($until: xl) {

        &__head {
            padding-bottom: 3vw;
        }

        &__wrpper {
            height: 44vw;
        }

    }

    @include mq($until: md) {
        // margin-top: 75px;
    }

    @include mq($until: lg) {
        &__photo {
            p img {
                height: 27vw;
            }
        }
    }

    @include mq($until: sm) {

        &__head {
            padding-left: 9%;
        }

        &__photo {
            p img {
                height: 30vw;
            }

            &--1{
                top:22vw;
            }
    
            &--3{
                top:22vw;
            }
            
            &--2 {
                left: 9vw;
            }

            &--3 {
                right: 9vw;
            }
        }

        &__wrpper {
            height: 50vw;
        }

    }

}
.offers{
    display: flex;
    justify-content: space-between;
    padding-bottom: 5vw;
    @include mq($until: sm) {
        flex-direction: column;
    }
    &__wrapper{
        max-width: 40vw;
        display: flex;
        margin: 2vw;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include mq($until: md) {
           max-width: unset;
        }
    }
    &__heading{
        padding-bottom: 21px;
        @include mq($until: md) {
            font-size: rem(21px);
        }
    }
    &__picture{
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        // @include mq($until: md) {
        //     max-height: unset;
        //  }
    }
}

.eight { 

    h2 {
        padding-top: 5vw;
        padding-bottom: 2vw;
    }

    &__map{
        height: 40vw;
        position: relative;
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.3);    
            top: 0;
            z-index: 0;
            pointer-events: none;
        }
    }

    @include mq($until: sm) {
        &__map {
            height: 70vw;
        }
    }

    @include mq($until: md) {
        margin-top: 70px;
    }
}

.flatlist-related {
    img {
        margin-top: 50px;
        margin-bottom: 40px;
    }

    @include mq($until: md) {
        margin-top: 100px;
        img {
            display: none;
        } 
    }
    &__widget{
        position: relative;
        @include mq($until: sm) {
            overflow: scroll;
            svg{
                width: 450vw;
            }
        }
        @include mq($until: xs) {
            overflow: scroll;
            svg{
                width: 550vw;
            }
        }
    }
}

.w-spacer {
    background-size: cover;
    position: relative;
    padding: 6vw 0;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.90);    
        top: 0;
        z-index: 0;
    }

    * {
        z-index: 5;
        position: relative;
    }

    h2 {
        text-align: center;
        margin-bottom: 40px;
    }

    &__box {
        width: 100%;
        height: 690px;
        max-width: 1130px;
        margin: 0 auto;
    }

    @include mq($until: md) {
        padding: 15vw 0;
        &__box {
            height: 590px;
        }
    }
}

.gallery-section {

    background-color: #3F3F3F;
    padding-top: 5vw;

    h2 {
        padding-left: 16%;
        color: white;
        font-size: rem(35px);
        // font-weight: 400;
        @include mq($until: sm) {
            text-align: center;
          }
    }

    @include mq($until: sm) {

        h2 {
            padding-left: 8%;
            padding-right: 15px;
        }
    }

    @include mq($until: xs) {

        h2 {
            padding-left: 15px;
        }
    }
}

.gallery {
    &-slider {
        margin: 0;
        li {
            height: 23vw;
        }

        a {
            height: 100%;
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            transform-origin: 67% 50%;
            transform: scale(1.15);
            box-shadow: 0px 0px 21px #00000029;
        }

        .slick-track {
            
            margin-top: 18vw;
            margin-bottom: 11vw;
            margin-left: -12vw;

            &> div:nth-child(2n){
            
                position: relative;
                bottom: 10vw;

                a {
                    transform-origin: 110% 50%;
                }
            }
        } 

        @include mq($until: sm) {
            a {
                transform: scale(1.35);
            }

            .slick-track {
                margin-top: calc(20vw + 44px);
                margin-bottom: 75px;
            }
        }

        @include mq($until: xs) {
            a {
                transform: scale(1.49);
            }
        }
    }
}
.icons-head{
    text-align: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
}