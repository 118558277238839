.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // min-height: 100px;
  position: absolute;
  align-items: center;
  width: 100%;
  z-index: 10;
  // border-bottom: solid 2px $color-main;
  &__logo{
    position: relative;
    z-index: 2;
    margin-top: 13px;
    padding-bottom: 13px;
    max-width: 80px;
    @include mq($until: xl) {
      max-width: 80px;
    }
    @include mq($until: md) {
      width: 50px;
    }
    @include mq($until: sm) {
      margin-top: 10px;
    }
    transition: width .3s ease-in-out;

  }

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;
    z-index: 10;

    &.sticky {
      background-color: $color-extra;
    }
  }

  &__left {
    display: flex;
    justify-items: baseline;
  }

  &__nav {
    margin-right: 50px;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      // background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #fff;
    font-weight: 400;
    margin-left: 25px;
    @include mq($until: lg) {
      margin-left: 0;
    }
    font-size: rem(17px);

    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
      color: #E0AC6A;
    }
  }

  .svg-icon {
    margin: 0 0 0 13px;
    width: 30px;
    fill: white;
    &:hover {
      fill:#E0AC6A;
    }
  }

  @include mq($until: md) {
    &__nav {
      margin-right: 0;
    }

    &__left {
      flex-direction: row-reverse;
      align-items: center;
    }

    &__hamburger {
      margin-left: 18px;
    }
  }

  @include mq($until: sm) {
    min-height: unset;
    height: 70px;
  }
  &__phone{
    margin: 0 0 0 18px;
    display: flex;
    align-items: center;
    transition: all .4s ease-in-out;
    p{
      color: #fff;
      margin-left: 8px;
      margin-bottom: 0;
      line-height: 0;
    }
    svg{
      width: 30px;
      fill: #fff;
    }
    &:hover > *{
      color: $color-main;
      fill: $color-main ;
    }
  }
  &__head-number{
    @include mq($until: md) {
      display: none;
    }

    transition: all .4s ease-in-out;
  }
  svg{
    transition: all .4s ease-in-out;
    &:hover{
      fill: $color-main;
    }
  }
  &__buton-sub{
    display: inline-block;
    padding: 13px 21px;
    color: $color-main;
    background-color: $color-extra;
    transition: all .4s ease-in-out;
    &:hover{  
      color: $color-extra;
         background-color: $color-main;
    }
  }
}

.header--fixed.sticky{
  img{
    max-width: 60px;
    transition: width .3s ease-in-out;
    @include mq($until: sm) {
      width: 45px;
    }
  }
} 